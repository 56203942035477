/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect } from 'react'
import { isIOS, isAndroid } from 'react-device-detect'

const appStoreLink = 'https://apps.apple.com/us/app/denario/id1438111232'
const googlePlayLink =
  'https://play.google.com/store/apps/details?id=br.com.madeinweb.denario&hl=pt_BR'

const DownloadPage = () => {
  function redirectToAppDownloadLink() {
    if (typeof window === 'undefined') return

    if (isIOS) {
      window.location.replace(appStoreLink)
      return
    }

    if (isAndroid) {
      window.location.replace(googlePlayLink)
      return
    }

    window.location.replace('/')
  }

  useEffect(() => {
    redirectToAppDownloadLink()
  })

  return <p>Redirect...</p>
}

export default DownloadPage
